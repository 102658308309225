body {
  background: #f3f3f3;
  font-family: 'Fira Sans', sans-serif;

}
.btn-primary {
  color: rgb(14, 13, 13);
  background-color: #DDC6B6;
  border-color: #DDC6B6;
}
.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle,.btn-primary:hover,.btn-primary:focus {
  color: rgb(0, 0, 0);
  background-color: #c7b5a8;
  border-color: #c7b5a8;
}
.btn {
  padding: 8px 20px;
  /* border-radius: 50px; */
  font-weight: 500;
}
.loginform {
  height: 100vh;
  display: flex;
  align-items: center;
  width: 100% ;
}
.loginform:before {
  content: '';
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url(./assets/images/abg-min.jpg);
  background-size: cover;
  z-index: -1;
}
/* pages */
.headers {
  display: flex;
  justify-content: space-between;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 1%;
  background-color: white;
}
.headers button.btn.btn-primary {
  border-radius: 30px;
  padding-left: 40px;
  padding-right: 40px;
  background-color: #3a3afb;
}
.menu {
  background-color: white;
  display: flex;
  padding-left: 4%;
  padding-right: 4%;
  padding-bottom: 1%;
}
.menupagination {
  display: flex;
  justify-content: space-between;
}
.pagination button:nth-child(1n+10) { display: none; }
.pagination button:last-child,.pagination button:nth-last-child(2) { display: block; }
.pagination button:nth-child(9) {
  margin-right: 30px;
  position: relative;
}

.pagination button:nth-child(9):after {
  content: '...';
  position: absolute;
  right: -30px;
  top: -2px;
  font-size: 24px;
}
.menu .dropdown {
  margin-left: 10px;
}
table {
  font-size: 14px;
}

.table>thead {
  font-weight: 700;
  font-size: 16px;
}

.table td, .table th {
  padding: 10px;
}
.table .action {
  font-size: 18px;
}

.table .action i {
  margin-right: 10px;
}
.table td:last-child, .table th:last-child {
  padding-right: 0;
}

.table td:first-child, .table th:first-child {
  padding-left: 0;
}

.table p {
  margin-bottom: 4px;
}

.pagination {
  margin-left: 5px;
}
button.active {
  background-color: #DDC6B6 !important;
  color: #000000;
  padding-left: 5px;
  padding-right: 5px;
}
input {
  padding: 8px 20px;
  margin-bottom: 10px;
  border-radius: 6px;
  border: 1px solid #646464;
  width: 100%;
  min-width: 100px;
}
.pagination button {
  border: none;
  margin-left: 10px;
  border-radius: 6px;
  padding: 10px 20px;
}

.sidebar {
  position: fixed;
  top: 90px;
  bottom: 0;
  left: 0;
  width: 300px;
  transition: all .3s;
  /* background-color: #099AAD; */
  background-image: url(./assets/images/sidebg.png);
  background-position: 10% 50%;
  background-size: cover;
}
.sidebar:after {
  content: '';
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  /* background-color: #111111; */
  opacity: 0.9;
}
.sidebar ul.menu-pst {
  padding: 40px 20px;
  margin: 0;
  position: relative;
    z-index: 1;
}
.nav-toogle img {
  width: 30px;
  cursor: pointer;
  filter: invert(1);
}
.logo {
  padding: 0 20px;
}
.logo img{
  width: 100px;
}
/* .menu-list{
list-style-type: none;
line-height: 20px;
} */
.menu-item {
  width: 100%;
  position: relative;
  cursor: pointer;
  list-style: none;
  padding: 10px 20px;
  color: #fff;
  font-weight: 500;
  transition: all .3s;
  margin-bottom: 2px;
}
.menu-item img {
  width: 22px;
  height: 22px;
}
.menu-item.active,.menu-item:hover {
  color: rgb(15, 15, 15);
  background-color: #DDC6B6;
  border-radius: 50px;
}
/* .menu-item.active::before,.menu-item:hover::before {
  content: "";
  border-left: 6px solid rgb(255 0 0);
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  border-radius: 3px;
} */
.menu-item i {
  margin-right: 10px;
}
.toggle_icon {
  width: 30px;
  height: 30px;
}
.toggle_btn {
  margin-top: 25px;
  position: absolute;
}
.wrapper {
  margin: 90px 0 0 300px;
  padding: 40px;
  transition: all .3s;
  overflow: hidden;
}


.custom-navigation {
  padding: 8px 30px;
  /* transition: all .3s; */
  background-color: #111111E6;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1;
  top: 0;
}
.form-control,.form-select {
  border-color: #C1C1C1;
  padding: 14px 20px;
  border-radius: 50px;
  color: #434343;
  font-weight: 500;
  background-color: #F6F6F6;
}
.custom-navigation form {
  background: #fff;
  border-radius: 30px;
  overflow: hidden
}
.custom-navigation .nav .dropdown .btn {
  padding: 0 10px;
  font-size: 16px;
  color: #ffffff;
  font-weight: 500;
  border: none !important;
}

.custom-navigation .nav .dropdown .btn img {
  margin-right: 10px;
}
.custom-navigation form input {
  border: 0;
  outline: none;
  box-shadow: 0;
}

.hide-sidebar .sidebar {
  left: -100%;
}

.hide-sidebar .wrapper {
  margin-left: 0;
}
.btn.focus, .btn:focus {
  outline: 0;
  box-shadow: none;
}
.ratingbox {
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 6px;
  border: 1px solid #DDC6B6;
  box-shadow: 0px 3px 6px #00000014;
}

.ratingbox p {
  margin: 0;
}
.ratingbox .icon i {
  font-size: 24px;
}
.card {
  border-color: #DDC6B6;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000014;
}
@media (max-width: 991px) {
  body.hide-sidebar {
      overflow: hidden;
  }
    .hide-sidebar:before {
      content: '';
      background-color: #0000008a;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 9;
  }
  .hide-sidebar .side-wrp {
      left: 0;
  }
}
.form-check-input:checked {
  background-color: #DDC6B6 ;
  border-color: #DDC6B6 ;
}
.form-switch .form-check-input {
  min-width: 40px;
  height: 20px;
}
.check {
  min-width: 20px;
  max-width: 20px;
}
.orderImage{
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.custom-switch{
  margin-left:18px;
}
.profimg{
  width: 50px;
  height:50px
}